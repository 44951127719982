#header {
    margin-top: 2rem;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* === CTA === */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* === SOCIALS === */
.header__socials {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* === ME === */
.me {
    width: 32rem;
    height: 32rem;
    left: calc(50% - 16rem);
    position: inherit;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

/* === MEDIA QUERIES (SMALL DEVICES) === */
@media screen and (max-width: 600px) {
    .me {
        width: 22rem;
        height: 22rem;
        left: calc(50% - 11rem);
    }
}
